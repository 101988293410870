.App-header{
    margin-bottom: 1rem;
}
.dev{
    border: solid 1px red;
}
.card-header{
    padding:1rem  !important;
}
.btn-uploader{
    padding:2rem;
    padding-bottom: 4rem;
    font-weight: bold;
    width: 100%;
    border: dashed 3px #ccc;
    border-radius: 5px;
    background-color: rgba(92, 144, 245, 0.4);
    margin-bottom: 1rem;
    color:#fff;
}
.upload__image-wrapper .image-item{
    border-bottom: solid 1px var(--bs-gray-600);
    text-align: center;
    padding:1rem;
}
.remove-all-images{
    width: 100%;
    margin-bottom: 1rem;
}
.share-buttons button{
    margin: 0.5rem;
}
:root {
    /*WYSIWYG editor*/
    /* Overrides the border radius setting in the theme. */
    --ck-border-radius: 3px;

    /* Overrides the default font size in the theme. */
    --ck-font-size-base: 16px;

    /* Helper variables to avoid duplication in the colors. */
    --ck-custom-background: hsl(270, 1%, 29%);
    --ck-custom-foreground: hsl(255, 3%, 18%);
    --ck-custom-border: hsl(300, 1%, 22%);
    --ck-custom-white: hsl(0, 0%, 100%);

    /* -- Overrides generic colors. ------------------------------------------------------------- */

    --ck-color-base-foreground: var(--ck-custom-background);
    --ck-color-focus-border: hsl(208, 90%, 62%);
    --ck-color-text: rgba(0, 0, 0,1);
    --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
    --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

    /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

    --ck-color-button-default-background: var(--ck-custom-background);
    --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
    --ck-color-button-default-active-background: hsl(270, 2%, 20%);
    --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
    --ck-color-button-default-disabled-background: var(--ck-custom-background);

    --ck-color-button-on-background: var(--ck-custom-foreground);
    --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
    --ck-color-button-on-active-background: hsl(255, 4%, 14%);
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
    --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

    --ck-color-button-action-background: hsl(168, 76%, 42%);
    --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
    --ck-color-button-action-active-background: hsl(168, 76%, 36%);
    --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
    --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
    --ck-color-button-action-text: var(--ck-custom-white);

    --ck-color-button-save: hsl(120, 100%, 46%);
    --ck-color-button-cancel: hsl(15, 100%, 56%);

    /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

    --ck-color-dropdown-panel-background: var(--ck-custom-background);
    --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

    --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
    --ck-color-split-button-hover-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

    --ck-color-input-background: var(--ck-custom-background);
    --ck-color-input-border: hsl(257, 3%, 43%);
    --ck-color-input-text: hsl(0, 0%, 98%);
    --ck-color-input-disabled-background: hsl(255, 4%, 21%);
    --ck-color-input-disabled-border: hsl(250, 3%, 38%);
    --ck-color-input-disabled-text: hsl(0, 0%, 78%);

    /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

    --ck-color-labeled-field-label-background: var(--ck-custom-background);

    /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

    --ck-color-list-background: var(--ck-custom-background);
    --ck-color-list-button-hover-background: var(--ck-custom-foreground);
    --ck-color-list-button-on-background: hsl(208, 88%, 52%);
    --ck-color-list-button-on-text: var(--ck-custom-white);

    /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

    --ck-color-panel-background: var(--ck-custom-background);
    --ck-color-panel-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

    --ck-color-toolbar-background: var(--ck-custom-background);
    --ck-color-toolbar-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

    --ck-color-tooltip-background: hsl(252, 7%, 14%);
    --ck-color-tooltip-text: hsl(0, 0%, 93%);

    /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);

    /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

    --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
    --ck-color-widget-hover-border: hsl(43, 100%, 68%);
    --ck-color-widget-editable-focus-background: var(--ck-custom-white);

    /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

    --ck-color-link-default: hsl(190, 100%, 75%);
}
/* for our internal form builder */
.react-form-builder .react-form-builder-toolbar{
    width: 100%;
    height: 80vh;
    overflow: auto;
    margin-top:0 !important;
}
.form-place-holder{
    height: 80vh;
    overflow: auto;
}
.dark-col{
    background-color: #1e1e1e!important;
    color: #fff!important;
}
/* for the slide out entry method editor */
.add-edit-entry-method-form{
    padding:1rem;
}
.entry-method-edit-row{
    padding:.5rem;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 4px;
    margin-bottom: .5rem;

}

#simple-tabpanel-1 ul, #simple-tabpanel-2 li{
    padding:0;
    margin:0;
    list-style: none;
}
.table-name{
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
}
.pl-1{
    padding-left: 1rem;
}
.pl-2{
    padding-left: 2rem;
}
.MuiCardActions-root{ /*bottom of each card*/
    width: 100%;
    text-align: center;
    font-size: .7rem;
    margin-left: .5rem;
}


.dzu-dropzone {
    overflow: hidden!important;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border: 1px dashed #d9d9d9 !important;
    border-radius: 3px;
    font-weight: bolder!important;
    min-height: 75px!important;
}
.downloadable-file-area{
    border: 1px dashed #d9d9d9 !important;
    border-radius: 3px;
    font-weight: bolder!important;
    min-height: 75px !important;
    text-align: center;
    padding: 1rem;
}
.downloadable-file-area svg{
    height: 30px !important;
}
[name='max_nbr_downloads']{
    min-height: 43px!important;
}
.dzu-inputLabel{
    font-size: 1rem!important;
}
.vert-tab button{
    text-align: center;
    display: block;
    padding-top:1rem;
}
.vert-tab svg{
    display: block;
    margin: 0 auto;
    margin-bottom: .2rem;
}
.vert-tab-title{
    display: block;
    width: 100%;
    font-size: .6rem;
}
.tabs-mobile svg{
     display: block;
     margin: 0 auto;
     margin-bottom: .1rem;
        height: .7rem;
 }


.full-height-preview-frame{
    width: 100%;
    height: 100%;
    min-height: calc( 100vh - 64px);
}
.full-height-preview-frame-rounded{
    width: calc(100% - .6rem);
    height: 100%;
    border-radius: 4px;
    margin-left: .5rem;
    min-height: calc( 100vh - 100px);
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.style-preview-img{
    width:35px;
    height:35px;
    border-radius: 4px;
    margin-right: .5rem;
}
.color-preview-img{
    heigh: 35px;
    width: auto;
    border-radius: 4px;
}
.style-preview-buttons button{
    margin-right: .5rem;
}
.maximize-button{
    position: absolute !important;
    z-index: 2;
    margin-left: 137px!important;
}
.preview-frame-holder{
    text-align: center;
    max-width: 100%;
    overflow-x: auto;
}


::-webkit-scrollbar {
    width: 12px;
    background-color: #303030;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #555;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #777;
}

/* Style the scrollbars for a light theme */
.light ::-webkit-scrollbar {
    width: 12px;
    background-color: #f1f1f1;
}

.light ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
}

.light ::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

.MuiAccordionSummary-root[draggable] {
    cursor: move;
}
.accordion-summary-exclusion{
    z-index:1000;
    position:relative;
    padding-left: 10px;
}
.settings-area ul{
    margin-left: 0;
    padding-left: 0;
}
.settings-area li{
    list-style: none;
}
.settings-area li, li.ui-draggable-dragging, .settings-area {
    list-style: none;
}
.no-side-padding-panel .MuiBox-root{
    padding-left: 0;
    padding-right: 0;
}
.no-side-padding{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: green;
    text-align: center;
}

.circleText {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: white;
    text-align: center;
    line-height: 20px; /* add this line */
}
.table-ellipsis{
    width: 95%;
    display: block;
    text-overflow: ellipsis
}
.google-map{
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.12);
}
.map-icon{
    font-size: 2rem;
    color:red;
    margin-top: -2rem;
    margin-left: -1rem;
}
.stats-summary-number{
    font-size: 1.5rem;
    font-weight: 600;
}
.stats-summary-label{
    font-size: .8rem;
    font-weight: 600;
}
.contest-settings-panel {
    padding-left: 1rem;
    padding-right: 1rem;
}

.account-form{
    display: block;
    width: 100%;
}
.account-form div{
    margin-bottom: .3rem;
}



/** MOBILE STYLES**/
@media only screen and (max-width: 600px) {
    .contest-style-tabs button{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .contest-settings-panel {
        padding:0;
        width:100% !important;
    }
    .mobile-hidden{
        display: none!important;
    }
    .vert-tab-title{
        display: none;;
    }
    [aria-label="vertical outlined button group"]{
        margin: 0 auto;
    }
}
.text-center{
    text-align: center;
}
[role="menu"] a,[role="menu"] button{
    display: block;
    width: 100%;
    text-align: center;
}
.payment-modal{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: white;
    vertical-align: middle;
    margin-top:1rem;
}
.payment-modal-plan-area{
    text-align: center;
    padding:1rem;
}
.float-right{
    float: right;
}
.mt-2{
    margin-top: 1rem;
}
